.modal-container {
  display: flex;
  justify-content: center;
  align-items: center; /* 세로로 가운데 정렬 */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  padding: 20px; /* 내용물과의 여백 */
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  padding: 20px;
  width: 100%;
  max-height: 80%; /* 모달이 화면을 벗어나지 않도록 최대 높이 설정 */
  overflow-y: auto; /* 내용물이 많을 때 스크롤 가능하게 설정 */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.message-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
}



